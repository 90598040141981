import { Atom } from ":mods";
import { TUTORS_FORM_STEPS } from "../../const";
import { FormPageProps } from "../../model";
import { createExperienceSecondPageInputs } from "../../form";

export function ExperienceSecondPage({ onFormSubmit }: FormPageProps) {
  const FormInputs = createExperienceSecondPageInputs();
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    if (!FormInputs.Actions.canSubmit()) return;
    const values = FormInputs.Actions.getValuesSnakecase();
    onFormSubmit(values);
  };

  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
      <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={6} />

      <form class="flex flex-col items-left gap-20px text-16px w-630px">
      <h1 class="text-28px font-medium my-40px  ">
      Tell us about your museum experience
        </h1>
        <p>
        We are building a specialised programme specifically to address the needs of the Museum ecosystem within the Kingdom of Saudi Arabia.
        </p>
        <p>
        enhance the skills and knowledge of professionals in the museum industry and provide them with an opportunity to learn about the cultural heritage of Saudi Arabia.{" "}
        </p>
        <div class="flex flex-col gap-10px">
          <FormInputs.WorkInKsaProject.Label
            title="Have you ever worked on a museum or cultural project in KSA?"
            class="mb-10px"
          >
            <FormInputs.WorkInKsaProject.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.WorkInKsaProject.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkInKsaProject.Input type="radio" class="" placeholder="" name="worked_in_ksa" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkInKsaProject.Input type="radio" class="" placeholder="" name="worked_in_ksa" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.WorkInGccProject.Label
            title="Have you ever worked on a museum or cultural project in the GCC?"
            class="mb-10px"
          >
            <FormInputs.WorkInGccProject.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.WorkInGccProject.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkInGccProject.Input type="radio" class="" placeholder="" name="worked_in_gcc" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.WorkInGccProject.Input type="radio" class="" placeholder="" name="worked_in_gcc" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.AreaOfProffesionDirectlyToKSA.Label
            title="Is your area of proffesional practice or research link directly to KSA"
            class="mb-10px"
          >
            <FormInputs.AreaOfProffesionDirectlyToKSA.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.AreaOfProffesionDirectlyToKSA.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.AreaOfProffesionDirectlyToKSA.Input
              type="radio"
              class=""
              placeholder=""
              name="proffesional_work_ksa"
              id="true"
            />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.AreaOfProffesionDirectlyToKSA.Input
              type="radio"
              class=""
              placeholder=""
              name="proffesional_work_ksa"
              id="false"
            />
            <p class="text-16px">No</p>
          </div>
        </div>

        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
